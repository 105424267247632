import { Paper } from "@mui/material"
import { useQuery, useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { DataGridListPage } from "../../dataGrid/DataGridListPage"
import { AxiosErrorDataType } from "../../../../Shared.Utils"
import {
  getAdminUnifiedServiceBillings,
  setAdminServiceBillingAsManuallyCreated,
} from "../../../../../domain/portal/admin/billings/Billings.Repository"
import { ServiceBillingsColumns } from "./ServiceBillingsColumns"
import { useHistory } from "react-router-dom"
import {
  ServiceBillingType,
  UnifiedServiceBillingAdminResponse,
} from "../../../../../data/generated-sources/openapi/api"

export const ServiceBillingsList = () => {
  const { t } = useTranslation("service-billings")
  const history = useHistory()

  const {
    data: billings,
    isFetching,
    isError,
    error,
    refetch,
  } = useQuery(["getServiceBillings"], () => getAdminUnifiedServiceBillings())

  const {
    mutate: setManuallyCreated,
    isLoading: isManuallyCreating,
    isError: isManuallyCreatedError,
    error: manuallyCreatedError,
  } = useMutation(["setManuallyCreated"], (billingId: string) => setAdminServiceBillingAsManuallyCreated(billingId), {
    onSuccess: () => refetch(),
  })
  const errorMsg = (error || manuallyCreatedError) as AxiosErrorDataType

  const handleCellClick = (field: string, { billingType, id, contractId }: UnifiedServiceBillingAdminResponse) => {
    if (field === "zev") {
      return history.push(`/management-contracts/${contractId}`)
    }

    if (field === "isManuallyCreated") {
      return setManuallyCreated(id)
    }

    if (billingType === ServiceBillingType.INITIAL) {
      return history.push(`/billings/initial/details/${id}`)
    }

    if (billingType === ServiceBillingType.RECURRING) {
      return history.push(`/billings/recurring/details/${id}`)
    }
  }

  return (
    <Paper data-testid="serviceBilingsListPage">
      <ErrorAlert
        scrollOnDisplay
        visible={isError || isManuallyCreatedError}
        message={t(`error-codes:${errorMsg?.response?.data?.code ?? errorMsg?.code ?? "OTHER"}`)}
      />
      <DataGridListPage<UnifiedServiceBillingAdminResponse>
        loading={isFetching || isManuallyCreating}
        rows={billings ?? []}
        title={t("serviceBilling.title")}
        data-testid="serviceBilingsListTable"
        columns={ServiceBillingsColumns()}
        onCellClick={({ field, row }) => handleCellClick(field, row)}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        onActionButtonClick={() => history.push("/billings/recurring/create")}
      />
    </Paper>
  )
}
