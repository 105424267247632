import { Autocomplete, Checkbox, FilterOptionsState, FormControlLabel, TextField } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { ContractAdminResponse } from "../../../../../../data/generated-sources/openapi"
import { getAllContracts } from "../../../../../../domain/portal/admin/contracts/Contracts.Repository"
import { AxiosErrorDataType, CommonFormikProps } from "../../../../../Shared.Utils"
import { ErrorAlert } from "../../../../../../uikit/Shared.Alert"
import { IServiceBillingCreate } from "../../../../../../domain/portal/admin/billings/recurring/BillingsRecurring.Model"

export const ContractsSelector = ({
  values,
  errors,
  setFieldValue,
  handleBlur,
}: CommonFormikProps<IServiceBillingCreate>) => {
  const { t } = useTranslation("billings-recurring")
  const [useAllContracts, setUseAllContracts] = useState(false)

  const {
    data: contractsData,
    isLoading: isLoadingContracts,
    isError: isErrorContracts,
    error: errorContracts,
    remove: removeContracts,
  } = useQuery(["getAllAdminContracts"], () => getAllContracts(), {})

  const errorMsg = errorContracts as AxiosErrorDataType

  useEffect(() => {
    return () => removeContracts()
  }, [removeContracts])

  const useAllContractsForBilling = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUseAllContracts(event.target.checked)

    if (event.target.checked) {
      setFieldValue(
        "contractIds",
        contractsData?.elements?.map((contract) => contract.id),
      )
    } else {
      setFieldValue("contractIds", [])
    }
  }

  return (
    <>
      <ErrorAlert
        scrollOnDisplay
        visible={isErrorContracts}
        message={t(`error-codes:${errorMsg?.response?.data?.code ?? errorMsg?.code ?? "OTHER"}`)}
      />
      <Autocomplete
        sx={{ my: 4 }}
        multiple
        filterSelectedOptions
        onBlur={handleBlur}
        loading={isLoadingContracts}
        value={(contractsData?.elements || [])?.filter((contract) => values?.contractIds?.includes(contract?.id))}
        options={contractsData?.elements ?? []}
        getOptionLabel={(contract) => contract?.name}
        getOptionKey={(contract) => `${contract?.name}-${contract?.id}`}
        filterOptions={(options: ContractAdminResponse[], state: FilterOptionsState<ContractAdminResponse>) => {
          const query = state.inputValue.toLowerCase()
          return options.filter((item: ContractAdminResponse) => item.name.toLowerCase().includes(query))
        }}
        onChange={(_, value, reason) => {
          if ((reason === "selectOption" || reason === "removeOption") && value !== null) {
            setFieldValue(
              "contractIds",
              value.map((contract) => contract.id),
            )
          }
          if (reason === "clear") setFieldValue("contractIds", [])
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={t("form.field.selectContract")}
            helperText={errors.contractIds}
          />
        )}
        aria-errormessage="testttttt"
      />
      <FormControlLabel
        sx={{ mb: 4 }}
        label={`${t("form.field.allContracts")}`}
        control={<Checkbox checked={useAllContracts} onChange={useAllContractsForBilling} />}
      />
    </>
  )
}
