import { GridActionsColDef, GridRenderCellParams } from "@mui/x-data-grid"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { useTranslation } from "react-i18next"
import { Box, IconButton, Switch } from "@mui/material"
import { StatusView } from "../../../../../uikit/label/StatusView"
import { OpenIconNew } from "../../../../../uikit/Shared.Icon"
import DateUtils from "../../../../../services/utils/DateUtils"
import { ServiceBillingType } from "../../../../../data/generated-sources/openapi"
import { DefaultCell } from "../../../../../uikit/dataGridTable"

const ManuallyCreatedCell = ({ value }: GridRenderCellParams) => {
  if (value.billingType !== ServiceBillingType.INITIAL) {
    return <></>
  }
  return (
    <Box textAlign={"right"} sx={{ flex: 1 }}>
      <Switch checked={!!value?.isManuallyCreated} data-testid="manualBillMark" />
    </Box>
  )
}

export const ServiceBillingsColumns = () => {
  const { t } = useTranslation("service-billings")

  const serviceBillingsListColumns: Array<GridBaseColDef | GridActionsColDef> = [
    {
      flex: 150,
      field: "billingDate",
      headerName: t("billingDate"),
      valueGetter: (value: string) => DateUtils.getDeFormatDateWithMonthString(value),
      renderCell: ({ formattedValue, ...rest }) => <DefaultCell {...rest} value={formattedValue} />,
    },
    {
      flex: 200,
      field: "billingNumber",
      headerName: t("invoiceNumber"),
      renderCell: DefaultCell,
    },
    {
      flex: 120,
      field: "activeState",
      headerName: t("shared:status.label"),
      valueGetter: (value: string) => t(`shared:status.${value}`),
      renderCell: ({ row }) => <StatusView statusType={row.activeState} />,
    },
    {
      flex: 150,
      field: "billingType",
      headerName: t("billingType"),
      valueGetter: (value: string) => t(`shared:billingType.${value}`),
      renderCell: ({ formattedValue, ...rest }) => <DefaultCell {...rest} value={formattedValue} />,
    },
    {
      flex: 80,
      field: "numberOfBillings",
      headerName: t("anzahl"),
      renderCell: DefaultCell,
    },
    {
      flex: 20,
      field: "contractId",
      headerName: "ZEV",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: () => (
        <IconButton data-testid="goToZevBtn">
          <OpenIconNew />
        </IconButton>
      ),
    },
    {
      flex: 150,
      align: "right",
      type: "boolean",
      filterable: false,
      disableColumnMenu: true,
      field: "isManuallyCreated",
      headerName: t("manualBilling"),
      description: t("manualBilling"),
      valueGetter: (_, row) => row,
      renderCell: ManuallyCreatedCell,
      sortComparator: (v1, v2) => v1?.isManuallyCreated - v2?.isManuallyCreated,
    },
  ]
  return serviceBillingsListColumns
}
