import { buildingsEpic } from "./buildings/Buildings.Epic"
import { myProfileEpic } from "./my-profile/MyProfile.Epic"
import { productsEpic } from "./products/Products.Epic"
import { profilesEpics } from "./profiles/Profiles.Epic"
import { serviceComponentsEpic } from "./service-components/ServiceComponents.Epic"
import { settingsEpic } from "./settings/Settings.Epic"

export const adminPortalEpic = [
  ...profilesEpics,
  ...serviceComponentsEpic,
  ...buildingsEpic,
  ...myProfileEpic,
  ...productsEpic,
  ...settingsEpic,
]
